<template>
  <v-menu
    ref="menu"
    v-model="menu"
    v-bind="menuProps"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    allow-overflow
    nudge-top="-5"
    :min-width="minWidth"
    :nudge-left="left"
  >
    <template #activator="{ on }">
      <v-text-field
        v-model="dateRangeText"
        v-bind="$attrs"
        v-on="on"
        class="accentColor--text"
        background-color="defaultBg"
        :class="{'rotate-icon picker-is-open': menu}"
        flat
        solo
        @click:append="menu = !menu"
        append-icon="mdi-menu-down"
        item-color="selectItem"
        readonly
        hide-details
      />
    </template>
    <v-card :max-width="cardWidth" :min-width="cardWidth" color="defaultBg dateRangePicker" outlined>
      <v-card-text class="px-10 py-9">
        <v-row justify="space-between" no-gutters>
          <v-col
            v-if="checkSm || !custom"
            class="py-0"
            :sm="custom ? 4 : 12"
            :md="custom ? 2 : 12"
          >
            <v-list class="pa-0" color="defaultBg">
              <v-list-item
                class="px-0"
                v-for="(preset, index) in presets"
                v-model="isPresetActive[index]"
                :key="index"
                @click="selectPreset(index)"
                :ripple="false"
              >
                <v-list-item-content class="pt-0 pb-5">
                  {{ preset.label }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                class="px-0"
                @click="customRange"
                :ripple="false"
              >
                <v-list-item-content class="py-0">
                  Custom range
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-slide-x-transition>
            <v-col class="py-0 pt-2" cols="12" sm="8" md="10" v-if="rangePicker">
              <v-row>
                <v-col class="pa-0" cols="12" md="6">
                  <div class="flex pl-2 pb-6" v-if="!checkSm">
                    <v-icon color="drawerMenuIcon" @click="customRange">$backIcon</v-icon>
                    <span class="pl-6">Custom range</span>
                  </div>
                  <v-date-picker
                    class="mx-2 mx-sm-4"
                    v-model="dates"
                    color="secondary"
                    no-title
                    :max="max"
                    range
                    @dblclick:date="applyRange"
                  />
                </v-col>
                <v-col class="pa-0" cols="12" sm="6" v-if="checkMd">
                  <v-date-picker
                    class="mx-4 mx-8"
                    v-model="dates"
                    color="secondary"
                    no-title
                    :max="max"
                    range
                    @dblclick:date="applyRange"
                  />
                </v-col>
                <v-col class="pb-0 pr-4 pt-8 d-flex justify-end" cols="12">
                  <v-btn
                    class="subtitle-1 accentColor--text error mx-8"
                    depressed
                    height="46"
                    @click="menu = !menu"
                  >
                    Cancel
                  </v-btn>
                  
                  <v-btn
                    class="subtitle-1 accentColor--text secondary"
                    depressed
                    height="46"
                    @click="applyRange"
                  >
                    Apply
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-slide-x-transition>
        </v-row>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { format, subDays, startOfWeek, startOfMonth, subMonths, lastDayOfMonth, startOfYear } from 'date-fns'

const isoFormat = 'yyyy-MM-dd'
const defaultDate = function() {
  return format(new Date(), isoFormat)
}

export default {
  name: 'DateRangePicker',
  inheritAttrs: false,
  props: {
    value: {
      type: Array,
      default: () => [defaultDate(), defaultDate()]
    },
    menuProps: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    menu: false,
    custom: false,
    rangePicker: false,
    dates: [defaultDate(), defaultDate()],
    max: defaultDate(),
    focusSelect: false,
    presets: [{
      label: 'Today',
      range: [
        format(new Date(), isoFormat),
        format(new Date(), isoFormat)
      ]
    }, {
      label: 'Yesterday',
      range: [
        format(subDays(new Date(), 1), isoFormat),
        format(subDays(new Date(), 1), isoFormat)
      ]
    }, {
      label: 'This Week',
      range: [
        format(startOfWeek(new Date(), {weekStartsOn: 1}), isoFormat),
        format(new Date(), isoFormat)
      ]
    }, {
      label: 'Last 7 Days',
      range: [
        format(subDays(new Date(), 6), isoFormat),
        format(new Date(), isoFormat)
      ]
    }, {
      label: 'Last 30 Days',
      range: [
        format(subDays(new Date(), 30), isoFormat),
        format(subDays(new Date(), 1), isoFormat)
      ]
    }, {
      label: 'This Month',
      range: [
        format(startOfMonth(new Date()), isoFormat),
        format(new Date(), isoFormat)
      ]
    }, {
      label: 'Last Month',
      range: [
        format(startOfMonth(subMonths(new Date(), 1)), isoFormat),
        format(lastDayOfMonth(subMonths(new Date(), 1)), isoFormat)
      ]
    }, {
      label: 'This Year',
      range: [
        format(startOfYear(new Date()), isoFormat),
        format(new Date(), isoFormat)
      ]
    }]
  }),
  computed: {
    dateRangeText: {
      get() {
        return this.value.map(date => format(new Date(date), isoFormat)).join(' ~ ')
      },
      set() {}
    },
    isPresetActive() {
      let findFirst = false
      const currentPreset = this.presets.map(preset => {
        if (!findFirst) {
          const result = (
            preset.range[0] === format(new Date(this.value[0]), isoFormat) &&
            preset.range[1] === format(new Date(this.value[1]), isoFormat)
          )
          findFirst = result
          return findFirst
        } else {
          return false
        }
      })
      this.$nextTick(() => {
        this.custom = this.rangePicker = currentPreset.every(item => !item)
      })
      return currentPreset
    },
    mobileXS() {
      return this.$vuetify.breakpoint.xs
    },
    mobileSM() {
      return this.$vuetify.breakpoint.sm
    },
    minWidth() {
      return this.custom ? 300 : 145
    },
    left() {
      return this.mobileXS && this.custom ? 25 : 0
    },
    cardWidth() {
      return this.custom ? this.checkMd ? 710 : this.checkSm ? 445 : 300 : 145
    },
    checkMd() {
      return this.$vuetify.breakpoint.width > 1029
    },
    checkSm() {
      return this.$vuetify.breakpoint.width > 599
    }
  },
  methods: {
    customRange() {
      if (this.custom) {
        this.rangePicker = false
        setTimeout(() => {
          this.custom = false
        }, 330)
      } else {
        this.rangePicker = true
        this.custom = true
      }
    },
    selectPreset(presetIndex) {
      this.dates = this.presets[presetIndex].range
      this.applyRange()
    },
    applyRange() {
      this.dates = this.dates.length === 1 ? [ this.dates[0], this.dates[0] ] : this.dates
      this.dates.sort((a, b) => Date.parse(a) - Date.parse(b))
      if (
        Date.parse(this.dates[0]) !== Date.parse(this.value[0])
        ||
        Date.parse(this.dates[1]) !== Date.parse(this.value[1])
      ) {
        this.$emit('input', this.dates)
      }
      this.menu = false
    }
  }
}
</script>
