<template>
  <v-dialog
    v-model="show"
    v-bind="$attrs"
    max-width="600px"
    persistent
    overlay-color="defaultBg"
  >
    <v-card class="mainBg">
      <div class="pb-10">
        <div class="defaultColor--text headline pl-15 py-15">
          Payment Link
        </div>
        
        <div class="px-15">
          <v-text-field
            v-model="paymentLink"
            ref="link"
            background-color="defaultBg"
            flat
            solo
            readonly
            >
            <template #append>
              <v-icon
                class="ml-3"
                color="defaultColor"
                @click="copyLink"
              >mdi-content-copy</v-icon>
            </template>
          </v-text-field>
        </div>
        
        <div class="d-flex justify-end px-15 py-10">
          <v-btn
            class="subtitle-1 accentColor--text defaultBg"
            large
            depressed
            height="50"
            @click="closeDialog"
          >Close</v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'DialogPaymentLink',
  inheritAttrs: false,
  data: () => ({
    show: false
  }),
  computed: {
    ...mapState({
      paymentLink: state => state.invoices.paymentLink
    })
  },
  methods: {
    ...mapMutations({
      clearLink: 'invoices/SET_PAYMENT_LINK'
    }),
    copyLink() {
      let textToCopy = this.$refs.link.$el.querySelector('input')
      textToCopy.select()
      document.execCommand('copy')
    },
    openDialogPay(item) {

    },
    closeDialog() {
      this.show = false
      this.clearLink()
    }
  },
  watch: {
    show: {
      handler(value) {
        if (!value) {
          this.closeDialog()
        }
      }
    },
    paymentLink: {
      handler(value) {
        if (value) {
          this.show = true
        }
      }
    },
  }
}
</script>
