<template>
  <v-dialog
    v-model="show"
    v-bind="$attrs"
    max-width="520px"
    persistent
    overlay-color="defaultBg"
  >
    <v-card class="mainBg">
      <v-form class="pb-10" ref="form" v-model="validForm">
        <div class="defaultColor--text headline pl-15 py-15">
          {{ getDialogName }}
        </div>
        
        <div class="px-15" v-if="role === 'Manager'">
          <div class="secondaryColor--text pb-7">Buyer</div>
          
          <v-autocomplete
            v-model="formData['buyer_id']"
            background-color="defaultBg"
            :items="buyers"
            item-text="email"
            item-value="id"
            flat
            solo
            :menu-props="{ 'nudge-top': -4 }"
            :rules="rules"
          />
        </div>
        
        <div class="px-15">
          <div class="pb-7">
            <span class="secondaryColor--text">Amount, $</span>
          </div>
          
          <v-text-field
            v-model="formData.amount"
            background-color="defaultBg"
            flat
            solo
            type="number"
            :rules="rules"
            min="0"
          />
        </div>
        
        <div class="px-15">
          <div class="pb-7">
            <span class="secondaryColor--text">Reason</span>
          </div>
          
          <v-textarea
            v-model="formData.description"
            background-color="defaultBg"
            flat
            solo
            auto-grow
          />
        </div>
        
        <div class="d-flex justify-end px-15 py-10">
          <v-btn
            class="subtitle-1 accentColor--text error mr-10"
            large
            depressed
            height="50"
            @click="closeDialog"
          >Cancel</v-btn>
          
          <v-btn
            class="subtitle-1 accentColor--text secondary"
            large
            depressed
            height="50"
            type="submit"
            @click.prevent="submitForm"
            :loading="loading"
          >Create</v-btn>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'DialogInvoice',
  inheritAttrs: false,
  props: {
    value: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    validForm: true,
    formData: {
      'buyer_id': null,
      amount: null,
      description: null
    },
    rules: [],
  }),
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    ...mapState({
      role: state => state.auth.role,
      user: state => state.auth.user,
      buyers: state => state.buyers.allItems,
      loading: state => state.invoices.loadingAfterAction,
      actionSuccess: state => state.invoices.actionSuccess,
    }),
    getDialogName() {
      return this.role === 'Manager' ? 'Request Payment' : 'Add Funds'
    }
  },
  methods: {
    ...mapActions({
      createInvoice: 'invoices/CREATE_INVOICE',
    }),
    async submitForm() {
      await this.formValidation()

      if (this.$refs.form.validate()) {
        if (this.role === 'Buyer') {
          this.formData['buyer_id'] = this.user.id
        }
        this.createInvoice(this.formData)
      }
    },
    formValidation() {
      this.rules = [
        v => !!v ||  'This field is required'
      ]
    },
    closeDialog() {
      this.show = false
    }
  },
  watch: {
    show: {
      handler(value) {
        if (!value) {
          this.formData = {
            buyer_id: null,
            amount: null,
            description: null
          }
          this.rules = []
        }
      }
    },
    actionSuccess: {
      handler(value) {
        if (value) {
          this.closeDialog()
        }
      }
    }
  }
}
</script>
